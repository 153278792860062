export const isMultiColorActive = true;
export const defaultColor = "light.red";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_selected_color";
export const adminRoot = "";
export const defaultMenuType = "menu-default sub-hidden";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultDirection = "ltr";
//
