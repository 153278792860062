/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

// USER LOGIN TYPES
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_USER = "LOGIN_USER";

// USER LOGOUT
export const LOGOUT = "LOGOUT";

// GET USER
export const USER_LOADED = "USER_LOADED";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
// IS USER AUTHENTICATED TYPES

export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
